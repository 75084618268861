import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Root from './../components/common/Root'
import Header from './../components/common/Header'
import Modal from './../components/common/Modal'
import HomepageHero from './../components/page-components/HomepageHero'
import Breakdown from '../components/page-components/Breakdown'
import Challenges from './../components/page-components/Challenges'
import Prizes from './../components/page-components/Prizes'
import FAQs from './../components/page-components/FAQs'
import SponsorsBanner from './../components/page-components/SponsorsBanner'
import Footer from './../components/common/Footer'

export default function Home({ location }) {
  const [emailSubmitted, setEmailSubmitted] = useState(location.search == '?thanks' ? true : false);

  return (
    <Root>
      <Helmet>
        <title>Bitcoin Alliance</title>
        <meta name="description" content="Bitcoin Alliance" />
        <meta property="og:title" content="Bitcoin Alliance - Comunicado de Prensa" />
        <meta property="og:image" content="https://i.ibb.co/tBTnFMf/Website-Thumbnail-Image-1.png" />
        <meta property="og:description" content="Banco Hipotecario is inviting coders, designers, entrepreneurs, and anyone with amazing ideas to the Bitcoin Bankathon - a fast-paced competition aimed at building innovative Bitcoin-based banking solutions that can unlock access to improved financial services for Salvadoran citizens." />
        <meta property="og:url" content="https://bitcoin-alliance.org/" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="626" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header hero={<HomepageHero/>} />
      <main>
        <Breakdown />
        <Challenges />
        <Prizes />
        <SponsorsBanner />
        <FAQs />
      </main>
      <Footer />
      <Modal show={emailSubmitted} />
    </Root>
  )
}
